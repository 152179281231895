// CalendarHeatmap.js
import React from 'react';
import ReactCalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Block } from 'baseui/block';
import '../heatmapStyles.css'; // Ensure this import is present

function CalendarHeatmap({ dateCounts }) {
  const dates = Object.keys(dateCounts);
  if (dates.length === 0) {
    return <Block>No data available for the past year.</Block>;
  }

  const values = dates.map((date) => ({
    date,
    count: dateCounts[date],
  }));

  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(0, 0, 0, 0);

  return (
    <Block
      overflow="auto"
      marginTop="scale600"
      maxWidth="800px"
      marginLeft="auto"
      marginRight="auto"
    >
      <ReactCalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        values={values}
        transformDayElement={(rect, value, index) => {
          if (!value || !value.date) {
            return rect;
          }
          return (
            <Tippy
              content={
                value.count
                  ? `${value.date}: ${value.count} pushups`
                  : `${value.date}: No pushups`
              }
              key={index}
            >
              {rect}
            </Tippy>
          );
        }}
        classForValue={(value) => {
          if (!value || value.count === 0) {
            return 'color-empty';
          }
          if (value.count < 10) return 'color-scale-1';
          if (value.count < 20) return 'color-scale-2';
          if (value.count < 30) return 'color-scale-3';
          return 'color-scale-4';
        }}
        showWeekdayLabels
      />
    </Block>
  );
}

export default CalendarHeatmap;
