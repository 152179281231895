import React, { useState, useEffect } from 'react';
import { Block } from 'baseui/block';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { auth } from '../firebase'; // Ensure your firebase is correctly configured
import { updateProfile, updateEmail } from 'firebase/auth';
import CommonNavBar from './NavBar';

function Settings() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch current user's profile information
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      console.log("user in settings page", user);
      const displayName = user.displayName ? user.displayName.split(' ') : ['', '']; // Handle null displayName
      setFirstName(displayName[0]);
      setLastName(displayName[1] || '');
      setEmail(user.email);
    }
  }, []);

  const handleUpdateProfile = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        // Update display name
        await updateProfile(user, {
          displayName: `${firstName} ${lastName}`.trim(),
        });

        // Update email
        if (email !== user.email) {
          await updateEmail(user, email);
        }

        setSuccess('Profile updated successfully!');
        setError('');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
      setSuccess('');
    }
  };

  return (
    <Block>
      <CommonNavBar />
      <Block padding="scale600">
        <h1>Settings</h1>
        <p>Update your profile information below:</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {success && <p style={{ color: 'green' }}>{success}</p>}
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="First Name"
          marginBottom="scale400"
        />
        <Block marginBottom="scale400" />
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Last Name"
          marginBottom="scale400"
        />
        <Block marginBottom="scale400" />
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          marginBottom="scale400"
        />
        <Block marginBottom="scale400" />
        <Button onClick={handleUpdateProfile}>Update Profile</Button>
      </Block>
    </Block>
  );
}

export default Settings;
