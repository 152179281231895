import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase"; // Ensure this points to your firebase config file
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import StatisticsPage from "./components/Profile";
import Settings from "./components/Settings";
import { PushupTracker2 } from "./components/PushupCounter";
import HomePage from "./components/HomePage";
import Demo from "./components/Demo";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Listen for authentication state to change.
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        console.log("User is signed in");
        setIsAuthenticated(true);
      } else {
        // User is signed out
        console.log("User is signed out");
        setIsAuthenticated(false);
      }
    });
    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/signin" element={isAuthenticated ? <Navigate to="/" /> : <SignIn />} />
        <Route path="/signup" element={isAuthenticated ? <Navigate to="/" /> : <SignUp />} />
        <Route path="/demo" element={isAuthenticated ? <Navigate to="/" /> : <Demo /> } />
        <Route path="/" element={isAuthenticated ? <HomePage /> : <Navigate to="/signin" />} />
        <Route path="/profile" element={isAuthenticated ? <StatisticsPage /> : <Navigate to="/signin" />} />
        <Route path="/settings" element={isAuthenticated ? <Settings /> : <Navigate to="/signin" />} />
        <Route path="/pushupcounter" element={isAuthenticated ? <PushupTracker2 /> : <Navigate to="/signin" />} />
        
        {/* Other routes can also be protected similarly */}
      </Routes>
    </Router>
  );
}

export default App;
