// usePushupHistory.js
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase'; // Adjust the path to your firebase config

function usePushupHistory() {
  const [pushupHistory, setPushupHistory] = useState({});

  useEffect(() => {
    async function fetchData() {
      const user = auth.currentUser;
      if (!user) {
        console.error('No authenticated user');
        return;
      }

      const userId = user.uid;
      const userDoc = doc(db, 'pushupCounts', userId);

      try {
        const docSnapshot = await getDoc(userDoc);
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          delete data.name; // Remove the name field if present
          setPushupHistory(data);
        } else {
          console.log('No pushup history found.');
        }
      } catch (error) {
        console.error('Error fetching pushup history:', error);
      }
    }

    fetchData();
  }, []);

  return pushupHistory;
}

export default usePushupHistory;
