import React, { useState, useEffect, useRef } from 'react';
import PushupTracker from './PushupTracker';
import CommonNavBar from './NavBar';
import { Block } from 'baseui/block';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import CameraSelection from './CameraSelection';
import CameraFeed from './CameraFeed';
import PoseDetection from './PoseDetection';
import pushupVideo from '../looped_pushups.mp4';

const FACING_MODE = {
  USER: 'user',
  ENVIRONMENT: 'environment',
};

// https://codepen.io/mozmorris/pen/KKwdOPO?editors=0010


export const PushupTracker2 = ({isDemo}) => {
  const [selectedCamera, setSelectedCamera] = useState('');
  const [facingMode, setFacingMode] = useState('user');
  const [videoElement, setVideoElement] = useState(null);
  const [pushupCount, setPushupCount] = useState(0);

  const videoRef = useRef(null);

  let lastState = 'up';

  useEffect(() => {
    return () => {
      console.log('PushupTracker2 unmounting');
      // Any cleanup if necessary
    };

  }, []);
  function updatePushupCountInFirestore(count) {
    if (isDemo) {
      return;
    }
    const user = auth.currentUser;
    if (!user) {
      console.error('No authenticated user');
      return;
    }

    const userId = user.uid;
    const today = new Date().toISOString().slice(0, 10);
    const userName = user.displayName || 'Anonymous';
    const userDoc = doc(db, 'pushupCounts', userId);

    setDoc(userDoc, {
      [today]: count,
      name: userName
    }, { merge: true }).then(() => {
      console.log('Firestore updated successfully');
    }).catch(error => {
      console.error('Error updating Firestore: ', error);
    });
  }

  function handleLandmarks(landmarks) {
    // History of elbow angles for smoothing
    const prevLeftAngles = [];
    const prevRightAngles = [];
  
    const currentState = detectPushupState(landmarks, prevLeftAngles, prevRightAngles);
  
    if (lastState === 'up' && currentState === 'down') {
      // Transition from up to down: start of a pushup
    } else if (lastState === 'down' && currentState === 'up') {
      // Transition from down to up: complete pushup
      setPushupCount(prevCount => {
        const newCount = prevCount + 1;
        updatePushupCountInFirestore(newCount);
        return newCount;
      });
    }
  
    if (currentState) {
      lastState = currentState;
    } else if (getAverageVisibilityScore(landmarks) < 0.5) {
      // Reset state if visibility is low for too long
      lastState = null;
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      setVideoElement(videoRef.current);

      // Ensure the video plays automatically
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.log('Auto-play was prevented:', error);
        });
      }
    }
  }, [videoRef]);

  return (
    <Block
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    padding="scale400"
    width="100%"
    height="100%"
    overrides={{
      Block: {
        style: {
          boxSizing: 'border-box',
        },
      },
    }}
  >
      {!isDemo && (<CommonNavBar />)}

      <Block
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        border="2px solid #ddd"
        borderRadius="10px"
        padding="10px"
        width="100%" // Ensure it spans the full width of the parent
        height="100%" // Ensure it spans the full height of the parent
        margin="20px auto"
      >
        <CameraSelection
          onCameraChange={setSelectedCamera}
          onFacingModeChange={setFacingMode}
        />

        <CameraFeed
          selectedCamera={selectedCamera}
          facingMode={facingMode}
          onCameraReady={(videoElement) => {
            videoRef.current = videoElement;
            setVideoElement(videoElement); // Ensure the videoElement is set
          }}
        /> 
      {/* <video
            ref={videoRef}
            src={pushupVideo}
            autoPlay
            loop
            muted
            controls // Optional: remove if you don't want controls
            style={{ display: 'none' }} // Hide the video element
      /> */}
        {videoElement && (
          <PoseDetection videoElement={videoElement} onLandmarksDetected={handleLandmarks} />
        )}

        <Block 
          marginTop="10px" 
          fontSize="18px" 
          textAlign="center"
        >
          Pushups Counted: {pushupCount}
        </Block>
      </Block>
    </Block>
  );
};

// function PushupCounter() {
//   const [pushupCount, setPushupCount] = useState(0);
//   const [cameras, setCameras] = useState([]);
//   const [selectedCamera, setSelectedCamera] = useState('');
//   const [facingMode, setFacingMode] = useState(FACING_MODE.USER);
//   const [isFacingModeSupported, setIsFacingModeSupported] = useState(true);
//   let lastState = 'up';

//   useEffect(() => {
//     const fetchPushupCount = async () => {
//       const user = auth.currentUser;
//       if (!user) {
//         console.error('No authenticated user');
//         return;
//       }

//       const userId = user.uid;
//       const today = new Date().toISOString().slice(0, 10);
//       const userDoc = doc(db, 'pushupCounts', userId);

//       try {
//         const docSnapshot = await getDoc(userDoc);
//         if (docSnapshot.exists()) {
//           const data = docSnapshot.data();
//           setPushupCount(data[today] || 0);
//         } else {
//           console.log('No document found for the user.');
//         }
//       } catch (error) {
//         console.error('Error fetching pushup count:', error);
//       }
//     };

//     fetchPushupCount();
//   }, []);

//   useEffect(() => {
//     const fetchCameras = async () => {
//       try {
//         const devices = await navigator.mediaDevices.enumerateDevices();
//         const videoDevices = devices.filter((device) => device.kind === 'videoinput');
//         setCameras(videoDevices);
//         if (videoDevices.length > 0) {
//           setSelectedCamera(videoDevices[0].deviceId);
//         }
//       } catch (error) {
//         console.error('Error fetching cameras:', error);
//       }
//     };

//     fetchCameras();
//   }, []);

//   useEffect(() => {
//     const checkFacingModeSupport = async () => {
//       if (!selectedCamera) return;

//       try {
//         await navigator.mediaDevices.getUserMedia({
//           video: {
//             deviceId: { exact: selectedCamera },
//             facingMode: { exact: facingMode }
//           }
//         });
//         setIsFacingModeSupported(true);
//       } catch (error) {
//         if (error.name === 'OverconstrainedError') {
//           setIsFacingModeSupported(false);
//         }
//       }
//     };

//     checkFacingModeSupport();
//   }, [selectedCamera, facingMode]);

//   function updatePushupCountInFirestore(count) {
//     const user = auth.currentUser;
//     if (!user) {
//       console.error('No authenticated user');
//       return;
//     }

//     const userId = user.uid;
//     const today = new Date().toISOString().slice(0, 10);
//     const userName = user.displayName || 'Anonymous';
//     const userDoc = doc(db, 'pushupCounts', userId);

//     setDoc(userDoc, {
//       [today]: count,
//       name: userName
//     }, { merge: true }).then(() => {
//       console.log('Firestore updated successfully');
//     }).catch(error => {
//       console.error('Error updating Firestore: ', error);
//     });
//   }

//   // function handleLandmarks(landmarks) {
//   //   const currentState = detectPushupState(landmarks);

//   //   if (lastState === 'up' && currentState === 'down') {
//   //     // Transition from up to down: start of a pushup
//   //   } else if (lastState === 'down' && currentState === 'up') {
//   //     // Transition from down to up: complete pushup
//   //     setPushupCount(prevCount => {
//   //       const newCount = prevCount + 1;
//   //       updatePushupCountInFirestore(newCount);
//   //       return newCount;
//   //     });
//   //   }

//   //   if (currentState) {
//   //     lastState = currentState;
//   //   }
//   // }

//   const toggleFacingMode = () => {
//     setFacingMode((prevMode) =>
//       prevMode === FACING_MODE.ENVIRONMENT ? FACING_MODE.USER : FACING_MODE.ENVIRONMENT
//     );
//   };
  
//   return (
//     <Block>
//       <CommonNavBar />
//       <Block>
//         <button onClick={toggleFacingMode}>
//           Switch Camera
//         </button>
//       </Block>
//       {isFacingModeSupported ? (
//         <PushupTracker
//           onLandmarksDetected={handleLandmarks}
//           selectedCamera={selectedCamera}
//           facingMode={facingMode}
//         />
//       ) : (
//         <PushupTracker
//           onLandmarksDetected={handleLandmarks}
//           selectedCamera={selectedCamera}
//         />
//       )}
//       <Block>Pushups Counted: {pushupCount}</Block>
//     </Block>
//   );
// }



// function detectPushupState(landmarks) {
//   const leftShoulder = landmarks[11];
//   const rightShoulder = landmarks[12];
//   const leftElbow = landmarks[13];
//   const rightElbow = landmarks[14];
//   const leftWrist = landmarks[15];
//   const rightWrist = landmarks[16];

//   const averageVisibility = getAverageVisibilityScore([
//     leftShoulder, rightShoulder, leftElbow, rightElbow, leftWrist, rightWrist
//   ]);

//   if (averageVisibility < 0.5) {
//     return null;
//   }

//   const leftElbowAngle = calculateAngle(leftShoulder, leftElbow, leftWrist);
//   const rightElbowAngle = calculateAngle(rightShoulder, rightElbow, rightWrist);

//   const upThreshold = 120;
//   const downThreshold = 75;

//   if (leftElbowAngle <= downThreshold || rightElbowAngle <= downThreshold) {
//     return 'down';
//   } else if (leftElbowAngle >= upThreshold && rightElbowAngle >= upThreshold) {
//     return 'up';
//   }

//   return null;
// }
function getAverageVisibilityScore(landmarks) {
  let totalScore = 0;
  let totalLandmarks = 0;
  for (let i = 0; i < landmarks.length; i++) {
    if (landmarks[i].visibility !== undefined) {
      totalScore += landmarks[i].visibility;
      totalLandmarks++;
    }
  }
  const average = totalScore / totalLandmarks;
  return average;
}

function isHorizontallyAligned(landmarks, tolerance = 0.1) {
  const leftShoulder = landmarks[11];
  const rightShoulder = landmarks[12];
  const leftHip = landmarks[23];
  const rightHip = landmarks[24];

  // Calculate the absolute difference in y-coordinates of key landmarks
  const shoulderDifference = Math.abs(leftShoulder.y - rightShoulder.y);
  const hipDifference = Math.abs(leftHip.y - rightHip.y);

  // Check if both shoulders and hips are within the horizontal tolerance
  const isAligned = shoulderDifference <= tolerance && hipDifference <= tolerance;
  return isAligned;
}

function detectPushupState(landmarks, prevLeftAngles, prevRightAngles) {
  const leftShoulder = landmarks[11];
  const rightShoulder = landmarks[12];
  const leftElbow = landmarks[13];
  const rightElbow = landmarks[14];
  const leftWrist = landmarks[15];
  const rightWrist = landmarks[16];

  const averageVisibility = getAverageVisibilityScore([
    leftShoulder,
    rightShoulder,
    leftElbow,
    rightElbow,
    leftWrist,
    rightWrist,
  ]);

  if (averageVisibility < 0.5) {
    return null; // Low visibility, unable to detect state
  }

  if (!isHorizontallyAligned(landmarks)) {
    return null; // Person is not horizontally aligned
  }

  const leftElbowAngle = calculateAngle(leftShoulder, leftElbow, leftWrist);
  const rightElbowAngle = calculateAngle(rightShoulder, rightElbow, rightWrist);

  // Push the new angles to history
  prevLeftAngles.push(leftElbowAngle);
  prevRightAngles.push(rightElbowAngle);

  // Smooth angles using moving average
  const smoothedLeftElbowAngle = calculateMovingAverage(prevLeftAngles, 5);
  const smoothedRightElbowAngle = calculateMovingAverage(prevRightAngles, 5);

  const upThreshold = 160; // Threshold angle for "up" position
  const downThreshold = 90; // Threshold angle for "down" position

  if (
    smoothedLeftElbowAngle <= downThreshold ||
    smoothedRightElbowAngle <= downThreshold
  ) {
    return 'down';
  } else if (
    smoothedLeftElbowAngle >= upThreshold &&
    smoothedRightElbowAngle >= upThreshold
  ) {
    return 'up';
  }

  return null;
}

function calculateMovingAverage(arr, windowSize) {
  if (arr.length < windowSize) {
    return arr[arr.length - 1]; // Not enough data points
  }
  const start = arr.length - windowSize;
  const windowData = arr.slice(start);
  const average =
    windowData.reduce((sum, value) => sum + value, 0) / windowSize;
  return average;
}

function calculateAngle(pointA, pointB, pointC) {
  const vectorBA = {
    x: pointA.x - pointB.x,
    y: pointA.y - pointB.y,
  };
  const vectorBC = {
    x: pointC.x - pointB.x,
    y: pointC.y - pointB.y,
  };

  const dotProduct =
    vectorBA.x * vectorBC.x + vectorBA.y * vectorBC.y;
  const magnitudeBA = Math.sqrt(
    vectorBA.x * vectorBA.x + vectorBA.y * vectorBA.y
  );
  const magnitudeBC = Math.sqrt(
    vectorBC.x * vectorBC.x + vectorBC.y * vectorBC.y
  );

  const cosineAngle = dotProduct / (magnitudeBA * magnitudeBC);

  // Ensure the cosine value is within the valid range [-1, 1]
  const clampedCosine = Math.min(Math.max(cosineAngle, -1), 1);

  const angleRadians = Math.acos(clampedCosine);
  const angleDegrees = angleRadians * (180 / Math.PI);

  return angleDegrees;
}


// export default PushupCounter