import React, { useState } from 'react';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { Block } from 'baseui/block';
import { HeadingXLarge, ParagraphSmall, LabelMedium } from 'baseui/typography';
import { PushupTracker2 } from './PushupCounter';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in successfully with email:", userCredential.user.email);
      navigate('/'); // Redirect to home or dashboard page
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  return (
    <Block
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
      backgroundColor="#f5f5f5"
    >
      <HeadingXLarge marginTop="scale1000" marginBottom="scale400">Pushup Counter</HeadingXLarge>
      <ParagraphSmall marginBottom="scale1000">An online tool that uses your camera to verify and count your pushups! </ParagraphSmall>
      {/* <PushupTracker2 isDemo={true}/> */}
      <Block
        width={['90%', '70%', '50%', '30%']}
        padding="scale600"
        backgroundColor="white"
        borderRadius="scale300"
        boxShadow="shadow400"
      >
        <Block marginBottom="scale600" display="flex" justifyContent="center">
          <LabelMedium font="font550">Sign In</LabelMedium>
        </Block>
        <Input 
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          marginBottom="scale400"
        />
        <Input 
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          marginBottom="scale400"
        />
        <Block marginBottom="scale400" />
        <Block display="flex" justifyContent="space-between" alignItems="center">
          <Button kind="minimal" onClick={() => navigate('/signup')}>Don't have an account? Sign Up</Button>
          <Button onClick={handleSignIn}>Sign In</Button>
        </Block>
        <Block marginTop="scale600" display="flex" justifyContent="center">
          <Button onClick={() => navigate('/demo')}>Try Demo</Button>
        </Block>
      </Block>
    </Block>
  );
}

export default SignIn;
