import React, { useEffect, useRef } from 'react';
import { Block } from 'baseui/block';
import * as pose from '@mediapipe/pose';
import * as drawingUtils from '@mediapipe/drawing_utils';

function PushupTracker({ onLandmarksDetected, selectedCamera, facingMode }) {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const mpPoseRef = useRef(null);
  const isMountedRef = useRef(false);
  const animationFrameIdRef = useRef(null);

  useEffect(() => {
    isMountedRef.current = true;

    // Initialize the Pose model
    const initializePose = () => {
      if (mpPoseRef.current) {
        mpPoseRef.current.close();
      }

      const localMpPose = new pose.Pose({
        locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
      });

      localMpPose.setOptions({
        selfieMode: facingMode === 'user',
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: false,
        minDetectionConfidence: 0.9,
        minTrackingConfidence: 0.9,
      });

      localMpPose.onResults(onResults);
      mpPoseRef.current = localMpPose;
    };

    initializePose();

    // Start the video stream and processing loop
    const initializeCamera = async () => {
      try {
        const constraints = {
          video: {
            deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
            facingMode: facingMode ? { exact: facingMode } : undefined,
            width: 1280,
            height: 720,
          },
          audio: false,
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        if (webcamRef.current) {
          webcamRef.current.srcObject = stream;
          webcamRef.current.play();
        }

        // Start processing frames
        processVideoFrame();
      } catch (error) {
        console.error('Error initializing camera:', error);
      }
    };

    const processVideoFrame = async () => {
      if (
        !isMountedRef.current ||
        !mpPoseRef.current ||
        !webcamRef.current ||
        webcamRef.current.readyState !== 4 // Ensure video is ready
      ) {
        return;
      }

      await mpPoseRef.current.send({ image: webcamRef.current });

      // Continue processing if component is still mounted
      if (isMountedRef.current) {
        animationFrameIdRef.current = requestAnimationFrame(processVideoFrame);
      }
    };

    initializeCamera();

    return () => {
      isMountedRef.current = false;

      // Stop the video stream
      if (webcamRef.current && webcamRef.current.srcObject) {
        webcamRef.current.srcObject.getTracks().forEach((track) => track.stop());
        webcamRef.current.srcObject = null;
      }

      // Cancel the animation frame
      if (animationFrameIdRef.current) {
        cancelAnimationFrame(animationFrameIdRef.current);
      }

      // Close the MediaPipe Pose model
      if (mpPoseRef.current) {
        mpPoseRef.current.close();
        mpPoseRef.current = null;
      }
    };
  }, [selectedCamera, facingMode]);

  // Process pose results and draw on the canvas
  function onResults(results) {
    if (!isMountedRef.current) {
      // Component is unmounted
      return;
    }

    if (!canvasRef.current || !results.image) {
      // Avoid logging to prevent console flood
      return;
    }

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    canvasElement.width = 1280;
    canvasElement.height = 720;
    canvasCtx.clearRect(0, 0, 1280, 720);
    canvasCtx.drawImage(results.image, 0, 0, 1280, 720);

    if (results.poseLandmarks) {
      drawingUtils.drawConnectors(canvasCtx, results.poseLandmarks, pose.POSE_CONNECTIONS, {
        color: 'white',
        lineWidth: 2,
      });
      drawingUtils.drawLandmarks(canvasCtx, results.poseLandmarks, {
        color: 'white',
        fillColor: 'rgb(255,138,0)',
        radius: (data) => (data.visibility < 0.65 ? 0 : 3),
      });
      onLandmarksDetected(results.poseLandmarks);
    }
  }

  return (
    <Block
      overrides={{
        Block: {
          style: {
            width: '100%',
            maxWidth: '1280px',
            height: 'auto',
            aspectRatio: '16 / 9',
          },
        },
      }}
    >
      <video
        ref={webcamRef}
        style={{ display: 'none' }}
        playsInline
        autoPlay
        muted
        width="1280"
        height="720"
      />
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }}></canvas>
    </Block>
  );
}

export default PushupTracker;
