import React from "react";
import { Block } from "baseui/block";
import { Routes, Route } from "react-router-dom";
import Profile from "./Profile";
import PushupTracker from "./PushupTracker";
import Settings from "./Settings";

export function HomePage() {
  return (
    <Block>
      <Routes>
        <Route path="/" element={<Profile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/PushupTracker" element={<PushupTracker />} />
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </Block>
  );
}

export default HomePage;
