import React, { useState } from 'react';
import { auth } from '../firebase'; // Assuming you have this setup
import { Input } from 'baseui/input';
import { Button } from 'baseui/button';
import { useNavigate } from 'react-router-dom';
import { Block } from 'baseui/block';
import { HeadingXLarge, ParagraphSmall, LabelMedium } from 'baseui/typography';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { PushupTracker2 } from './PushupCounter';

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const displayName = `${firstName} ${lastName}`.trim();

      await updateProfile(user, {
        displayName: displayName,
      });

      console.log('User created successfully with email:', user.email);
      console.log('Display Name set to:', displayName);

      navigate('/');

    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  return (
    <Block
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height='100vh'
      width='100%'
      backgroundColor='#f5f5f5'
      padding='scale800'
      overflow='auto' // Allow scrolling if content overflows
    >
      {/* Header Section */}
      <Block 
        marginBottom="scale600" 
        display='flex' 
        flexDirection='column' 
        alignItems='center'
        width='100%'
        padding='scale600'
      >
        <HeadingXLarge>Pushup Counter</HeadingXLarge>
        <ParagraphSmall>An online tool that uses your camera to verify and count your pushups!</ParagraphSmall>
      </Block>

      {/* Demo Section with Adequate Space */}
      {/* <Block
        width={['100%', '80%', '70%', '60%']} 
        height={['30vh', '40vh', '50vh']} // Reduce height to make space for heading
        marginBottom='scale600'
        display='flex'
        alignItems='center'
        justifyContent='center'
        backgroundColor='#fff'
        border='2px solid #ddd'
        borderRadius='scale300'
        boxShadow='shadow400'
        padding='scale500'
      >
        <Block
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <PushupTracker2 isDemo={true} />
        </Block>
      </Block> */}

      {/* Sign-Up Form Section */}
      <Block
        width={['90%', '70%', '50%', '30%']}
        padding='scale600'
        backgroundColor='white'
        borderRadius='scale300'
        boxShadow='shadow400'
      >
        <Block marginBottom='scale600' display='flex' justifyContent='center'>
          <LabelMedium font='font550'>Sign Up</LabelMedium>
        </Block>
        <Input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          placeholder='First Name'
          marginBottom='scale400'
        />
        <Block marginBottom='scale400' />
        <Input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          placeholder='Last Name'
          marginBottom='scale400'
        />
        <Block marginBottom='scale400' />
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder='Email'
          marginBottom='scale400'
        />
        <Block marginBottom='scale400' />
        <Input
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder='Password'
          marginBottom='scale400'
        />
        <Block marginBottom='scale400' />
        <Block display='flex' justifyContent='space-between' alignItems='center'>
          <Button kind='minimal' onClick={() => navigate('/signin')}>
            Already have an account? Sign In
          </Button>
          <Button onClick={handleSignUp}>Sign Up</Button>
        </Block>
      </Block>
    </Block>
  );
}

export default SignUp;
