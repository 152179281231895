// StatisticsPage.js
import React from 'react';
import { Block } from 'baseui/block';
import { HeadingMedium } from 'baseui/typography';
import CommonNavBar from './NavBar'; // Adjust the import path
import usePushupHistory from './usePushupHistory';
import CalendarHeatmap from './CalendarHeatmap';
import '../heatmapStyles.css'; // Import the heatmap styles

function StatisticsPage() {
  const pushupHistory = usePushupHistory();
  const dateCounts = processPushupData(pushupHistory);

  const totalPushups = Object.values(dateCounts).reduce((sum, count) => sum + count, 0);

  return (
    <Block padding="scale600">
      <CommonNavBar />
      <Block display="flex" flexDirection="column" alignItems="center">
        <HeadingMedium>Pushup Heatmap</HeadingMedium>
        <Block marginBottom="scale400">Total Pushups: {totalPushups}</Block>
      </Block>
      <CalendarHeatmap dateCounts={dateCounts} />
    </Block>
  );  
}

function processPushupData(pushupHistory) {
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(0, 0, 0, 0);

  const dateCounts = {};

  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    const dateStr = d.toISOString().slice(0, 10);
    dateCounts[dateStr] = pushupHistory[dateStr] || 0;
  }

  return dateCounts;
}

export default StatisticsPage;
