// import React, { useEffect, useRef, useState } from 'react';
// import * as pose from '@mediapipe/pose';
// import * as drawingUtils from '@mediapipe/drawing_utils';

// function PoseDetection({ videoElement, onLandmarksDetected }) {
//   const canvasRef = useRef(null);
//   const [mpPose, setMpPose] = useState(null);

//   useEffect(() => {
//     if (!videoElement) {
//       console.log('No video element available');
//       return;
//     }

//     console.log('Video element is available:', videoElement);

//     const initializePose = () => {
//       const localMpPose = new pose.Pose({
//         locateFile: (file) =>
//           `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
//       });

//       localMpPose.setOptions({
//         selfieMode: false,
//         modelComplexity: 1,
//         smoothLandmarks: true,
//         enableSegmentation: false,
//         smoothSegmentation: false,
//         minDetectionConfidence: 0.5,
//         minTrackingConfidence: 0.5,
//       });

//       localMpPose.onResults((results) => {
//         if (!canvasRef.current || !results.image) {
//           console.log('Canvas or results.image not available');
//           return;
//         }

//         console.log('Pose results received:', results);

//         const canvasElement = canvasRef.current;
//         const canvasCtx = canvasElement.getContext("2d");

//         // Set canvas dimensions to match video aspect ratio
//         const videoWidth = videoElement.videoWidth;
//         const videoHeight = videoElement.videoHeight;
//         const aspectRatio = videoWidth / videoHeight;

//         if (window.innerWidth / window.innerHeight > aspectRatio) {
//           // If window is wider than video aspect ratio
//           canvasElement.height = window.innerHeight;
//           canvasElement.width = window.innerHeight * aspectRatio;
//         } else {
//           // If window is taller than video aspect ratio
//           canvasElement.width = window.innerWidth;
//           canvasElement.height = window.innerWidth / aspectRatio;
//         }

//         canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
//         canvasCtx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
//         console.log('Video drawn on canvas for debugging purposes');

//         if (results.poseLandmarks && results.poseLandmarks.length > 0) {
//           console.log('Landmarks detected:', results.poseLandmarks);
//           drawingUtils.drawConnectors(
//             canvasCtx,
//             results.poseLandmarks,
//             pose.POSE_CONNECTIONS,
//             {
//               color: "white",
//               lineWidth: 2,
//             }
//           );
//           drawingUtils.drawLandmarks(canvasCtx, results.poseLandmarks, {
//             color: "white",
//             fillColor: "rgb(255,138,0)",
//             radius: (data) => (data.visibility < 0.65 ? 0 : 3),
//           });
//           console.log('Drawing landmarks on canvas');
//           onLandmarksDetected(results.poseLandmarks);
//         } else {
//           console.log('No landmarks detected.');
//         }
//       });

//       console.log('Pose model initialized');
//       setMpPose(localMpPose);
//     };

//     initializePose();

//     return () => {
//       if (mpPose) {
//         console.log('Closing Pose model');
//         mpPose.close();
//       }
//     };
//   }, [videoElement]);

//   useEffect(() => {
//     const handleResize = () => {
//       if (canvasRef.current && videoElement) {
//         const videoWidth = videoElement.videoWidth;
//         const videoHeight = videoElement.videoHeight;
//         const aspectRatio = videoWidth / videoHeight;

//         if (window.innerWidth / window.innerHeight > aspectRatio) {
//           canvasRef.current.height = window.innerHeight;
//           canvasRef.current.width = window.innerHeight * aspectRatio;
//         } else {
//           canvasRef.current.width = window.innerWidth;
//           canvasRef.current.height = window.innerWidth / aspectRatio;
//         }
//       }
//     };

//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, [videoElement]);

//   useEffect(() => {
//     if (mpPose && videoElement) {
//       const processFrame = async () => {
//         if (videoElement.readyState >= 2) {
//           console.log('Processing frame with video element state:', videoElement.readyState, videoElement.videoWidth, videoElement.videoHeight);
//           await mpPose.send({ image: videoElement });
//         } else {
//           console.log('Video element is not ready');
//         }
//         requestAnimationFrame(processFrame);
//       };
//       requestAnimationFrame(processFrame);
//     }
//   }, [mpPose, videoElement]);

//   return <canvas ref={canvasRef} style={{ width: "100%", height: "auto" }} />;
// }

// export default PoseDetection;
import React, { useEffect, useRef, useState } from 'react';
import * as pose from '@mediapipe/pose';
import * as drawingUtils from '@mediapipe/drawing_utils';
import { Block } from 'baseui/block';

function PoseDetection({ videoElement, onLandmarksDetected }) {
  const canvasRef = useRef(null);
  const [mpPose, setMpPose] = useState(null);

  useEffect(() => {
    if (!videoElement) {
      console.log('No video element available');
      return;
    }

    const initializePose = () => {
      const localMpPose = new pose.Pose({
        locateFile: (file) =>
          `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`,
      });

      localMpPose.setOptions({
        selfieMode: false,
        modelComplexity: 1,
        smoothLandmarks: true,
        enableSegmentation: false,
        smoothSegmentation: false,
        minDetectionConfidence: 0.75,
        minTrackingConfidence: 0.75,
      });

      localMpPose.onResults((results) => {
        if (!canvasRef.current || !results.image) {
          console.log('Canvas or results.image not available');
          return;
        }

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext("2d");

        // Calculate canvas size based on video aspect ratio
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;
        
        const containerWidth = canvasElement.parentElement.clientWidth;
        const containerHeight = canvasElement.parentElement.clientHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        if (containerAspectRatio > aspectRatio) {
          // Container is wider than video
          canvasElement.height = containerHeight;
          canvasElement.width = containerHeight * aspectRatio;
        } else {
          // Container is taller than video
          canvasElement.width = containerWidth;
          canvasElement.height = containerWidth / aspectRatio;
        }

        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
        canvasCtx.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

        if (results.poseLandmarks && results.poseLandmarks.length > 0) {
          drawingUtils.drawConnectors(
            canvasCtx,
            results.poseLandmarks,
            pose.POSE_CONNECTIONS,
            { color: "white", lineWidth: 2 }
          );
          drawingUtils.drawLandmarks(canvasCtx, results.poseLandmarks, {
            color: "white",
            fillColor: "rgb(255,138,0)",
            radius: (data) => (data.visibility < 0.65 ? 0 : 3),
          });
          onLandmarksDetected(results.poseLandmarks);
        } else {
          console.log('No landmarks detected.');
        }
      });

      console.log('Pose model initialized');
      setMpPose(localMpPose);
    };

    initializePose();

    return () => {
      if (mpPose) {
        mpPose.close();
      }
    };
  }, [videoElement]);

  useEffect(() => {
    const processFrame = async () => {
      if (mpPose && videoElement && videoElement.readyState >= 2) {
        await mpPose.send({ image: videoElement });
      }
      requestAnimationFrame(processFrame);
    };
    processFrame();
  }, [mpPose, videoElement]);

  useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current && videoElement) {
        const videoWidth = videoElement.videoWidth;
        const videoHeight = videoElement.videoHeight;
        const aspectRatio = videoWidth / videoHeight;
        
        const containerWidth = canvasRef.current.parentElement.clientWidth;
        const containerHeight = canvasRef.current.parentElement.clientHeight;
        const containerAspectRatio = containerWidth / containerHeight;

        if (containerAspectRatio > aspectRatio) {
          canvasRef.current.height = containerHeight;
          canvasRef.current.width = containerHeight * aspectRatio;
        } else {
          canvasRef.current.width = containerWidth;
          canvasRef.current.height = containerWidth / aspectRatio;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [videoElement]);

  return (
    <Block
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="20px"
      border="2px solid #ddd"
      borderRadius="10px"
      maxWidth="90%"
      maxHeight="80vh"
      margin="0 auto"
      overrides={{
        Block: {
          style: {
            boxSizing: 'border-box',
          },
        },
      }}
    >
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
    </Block>
  );
}

export default PoseDetection;
