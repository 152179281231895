// Demo.js
import React from 'react';
import { Block } from 'baseui/block';
import { Button } from 'baseui/button';
import { useNavigate } from 'react-router-dom';
import { HeadingXLarge, ParagraphSmall } from 'baseui/typography';
import { PushupTracker2 } from './PushupCounter'; // Import your demo component

function Demo() {
  const navigate = useNavigate();

  return (
    <Block
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'
      height='100vh'
      width='100%'
      backgroundColor='#f5f5f5'
      padding='scale800'
      overflow='auto' // Allow scrolling if content overflows
    >
      {/* Header Section */}
      <Block 
        marginBottom="scale600" 
        display='flex' 
        flexDirection='column' 
        alignItems='center'
        width='100%'
        padding='scale600'
      >
        <HeadingXLarge>Pushup Counter</HeadingXLarge>
        <ParagraphSmall>An online tool that uses your camera to automatically count your pushups!</ParagraphSmall>
      </Block>

      {/* Demo Section */}
      <Block
        width={['100%', '80%', '70%', '60%']} 
        // height={['30vh', '40vh', '50vh']} // Adjust the height as needed
        marginBottom='scale600'
        display='flex'
        alignItems='center'
        justifyContent='center'
        backgroundColor='#fff'
        border='2px solid #ddd'
        borderRadius='scale300'
        boxShadow='shadow400'
        padding='scale500'
      >
        <Block
          width='100%'
          height='100%'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <PushupTracker2 isDemo={true} />
        </Block>
      </Block>

      {/* Sign-In and Sign-Up Buttons */}
      <Block
        display='flex'
        justifyContent='space-between'
        width='300px' // Adjust width as needed
        marginTop='scale600'
      >
        <Button onClick={() => navigate('/signup')}>Sign Up</Button>
        <Button onClick={() => navigate('/signin')}>Sign In</Button>
      </Block>
    </Block>
  );
}

export default Demo;
