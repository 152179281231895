import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyBVY32SCpDqDcyG7soPDlW-gxpHpi-MRq0",
  authDomain: "pushupcounter-efa3b.firebaseapp.com",
  projectId: "pushupcounter-efa3b",
  storageBucket: "pushupcounter-efa3b.appspot.com",
  messagingSenderId: "741126166646",
  appId: "1:741126166646:web:c6772b668e42e17dec2757",
  measurementId: "G-JFHDX1K48T"
};

const app = initializeApp(firebaseConfig);
export { app as firebase};

export const db = getFirestore(app);
export const auth = getAuth(app);
